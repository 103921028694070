// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    width: 200px;
    height: 100vh;
    background-color: #1c1c1c;
    color: white;
    padding: 10px;
    box-sizing: border-box;
    border-right: 1px solid #333;
  }
  
  .menu {
    list-style-type: none;
    padding: 0;
  }
  
  .menu-item {
    cursor: pointer;
    padding: 10px;
    background-color: #34495e;
    margin-bottom: 5px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .menu-item:hover {
    background-color: #3d566e;
  }
  
  .submenu {
    list-style-type: none;
    padding-left: 20px;
  }
  
  .submenu-item {
    padding: 5px;
    background-color: #3d566e;
    margin-bottom: 5px;
    border-radius: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Sidebar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,4BAA4B;EAC9B;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":[".sidebar {\n    width: 200px;\n    height: 100vh;\n    background-color: #1c1c1c;\n    color: white;\n    padding: 10px;\n    box-sizing: border-box;\n    border-right: 1px solid #333;\n  }\n  \n  .menu {\n    list-style-type: none;\n    padding: 0;\n  }\n  \n  .menu-item {\n    cursor: pointer;\n    padding: 10px;\n    background-color: #34495e;\n    margin-bottom: 5px;\n    border-radius: 5px;\n    transition: background-color 0.3s;\n  }\n  \n  .menu-item:hover {\n    background-color: #3d566e;\n  }\n  \n  .submenu {\n    list-style-type: none;\n    padding-left: 20px;\n  }\n  \n  .submenu-item {\n    padding: 5px;\n    background-color: #3d566e;\n    margin-bottom: 5px;\n    border-radius: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
